/* Updated CSS */
.content-container {
  position: relative; /* This ensures the loading overlay stays within the content area */
}

.loading-overlay {
  position: absolute; /* Make it absolute within the content container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  z-index: 9999; /* Ensure it’s above all other elements */
  margin-top: auto;
}

.loading-overlay img {
  width: 100px; /* Adjust size of the loading GIF */
  height: 100px;
 
}
.label-left-space {
  margin-left: 40px; /* Adjust the value as needed */
}
